import * as React from "react";
import { Helmet } from "react-helmet";
import { useConfig } from "gatsby-theme-advanced";
import Layout from "../layouts";
import LighboxGallery from "../components/Art/LighboxGallery";
import {ABSTRACTS_SURREAL, PORTRAITS} from "../components/Art/Artworks";
import {Jadore} from "../images/JadoreLogo";

const PortraitsPage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <div className="portraits-container">
        <Helmet title={`Portraits | ${config.website.title}`} />
          <Jadore id={'jSlider'} className={'jSlider'} to={'/gallery'}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 707.5 104.11">
                  <g id="Ebene_1">
                      <path className="cls-1"
                            d="M511.46,18.82c4,3.31-20.81,11-28.95,10.93l-10.31-.18c8-7.27,32.26-11.51,39.26-10.75ZM284.3,29.29c-3.34,4.07-12,8-25.91,9.78l-6.37,0c5.48-8.7,19.24-8.39,32.28-9.83Zm53.23-2.73,8.5,1.58C337,30.9,326.77,35.67,316.52,37H305.76c2.23-3.18,20.56-9.56,31.77-10.47ZM403,30.63H414c.2,1,1.43.77,3.09,1.79-6.43,2.66-16.82,6.24-25.51,6.15h-6.22c1.9-4,7.75-6.46,17.7-7.94ZM193.92,39.4c-47,23.84-127.68,45.25-179.57,55.53,56-38,127.5-46.38,179.57-55.53ZM261.17,1.21c7.78,0,5.25,4.41,5.25,6.34,0,6.45-18.62,14.71-25.11,17.86a16.24,16.24,0,0,0,3.66,0c5.78-.25,8.3-2.27,20.17-2.7,12.48-.45,20.26,5,20.57,4.76,10.24-7.71,12-7.91,17.16-8.86,17.21-3.19-6.47,7.28-1.43,12.35,12.5-4.72,19.68-10,30.54-10,4,0,21.49.58,22.34,4.84C361.6,21,406.31,0,411.2,0c6,0,10.06,4.74,7.23,4.42C403.61,2.76,375,25.6,365.15,32.88c14.68-4.76,31.52-9.59,46-9.59,3.62,0,13.35,3.26,17,3.26s24.28-7.48,27.9-7.48,6.67,2.08,9.05,3.45c8.17-4.4,31.54-10,42.4-10,11.76,0,13.41,2.24,11.49,6.55-5,11.26-34.37,16.29-46.76,15.59-4.5-.26-4.12,5.91,3.5,5.66C482,40.11,625.62,27.7,684.87,20.6l22.63,4.49c-32,.74-213.88,19.3-231.8,19.3-13,0-24.63-2-21.86-7.56-.25.49-18.4,8.84-24.55,8.84-6.7,0-5.26-3.73-7.35-5.16-3.83,1.41-9.7,2.91-15.2,4.25.11.54,40.85,1,55,7.06,3.32,1.42-82.17-6.25-202.82,10.51-77.76,10.6-185.31,32-218.27,40.46-5.18,1.32-4.41,2.74-4.41-1.68C36.28,100,237,47.4,376.46,46.7a25.67,25.67,0,0,1-9.62-5.47c-2.38.72-7,2.23-10.66,2.23-5.79,0-8.51-5.16-14.48-5.16-2.89,0-24.43,5.16-31.85,5.16a85.82,85.82,0,0,1-20.08-2.76c-.54,0-17,4-25.32,4-12.48,0-28.86.51-28.86-5.84,0-1.84,1.16-3.06,3.94-5-1.24-.51-5.53.8-7.51,1.21-21.33,4.34-40.75,17.71-61.48,26.65-31.17,13.44-73.06,22.87-111.6,31C45.36,95.54,15.46,101.11,0,101.11c-.31-3.25,7.6-9.54,12.12-12.4C65.34,55.11,146,39.61,210.46,29.39c16.29-2.58,25.12-14.58,32.22-19.77-19.27,3.71-84.85,19.29-96.43,19.29-5.79,0-8.69-1.19-8.69-3.68,0-2.67,32.69-6.32,35.77-6.69,9.23-1.19,79.51-17.33,87.84-17.33Z"/>
                  </g>
              </svg></Jadore>
          <LighboxGallery artworks={PORTRAITS}></LighboxGallery>

      </div>
    </Layout>
  );
};

export default PortraitsPage;
